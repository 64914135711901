import React from 'react';
import { createRoot } from 'react-dom/client';
import { Stage, Layer, Line, Text, Image, Circle, Rect } from 'react-konva';
import { useEffect } from 'react'
import useImage from "use-image";
import { spaceIcon, currentToolIcon, ctrlZIcon } from "./icons.js";

const App = () => {
  const [tool, setTool] = React.useState('pen');
  const [items, setItems] = React.useState([]);
  const [strokeWidth, setStrokeWidth] = React.useState(5)
  const [fontSize, setFontSize] = React.useState(30)
  const isDrawing = React.useRef(false);
  const isWriting = React.useRef(false);
  const [mouseX, setMouseX] = React.useState(0)
  const [mouseY, setMouseY] = React.useState(0)
  const showHelper = React.useRef(true);
  const [showToolbar, setShowToolBar] = React.useState(false);


  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown])

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp)
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp])

  function handleKeyUp(e) {
    if (e.code == "Space" && showToolbar) {
      setShowToolBar(false)
    }
  }

  function handleKeyDown(e) {
    if (e.code == "Space" && doingNothing()) {
      setShowToolBar(true)
    }

    if (e.key == "Backspace" && isWriting.current) {
      let lastText = items[items.length - 1]
      lastText.text = lastText.text.slice(0, -1)
      setItems(items.concat());
      if (lastText.text.length == 0) {
        isWriting.current = false;
      }
    }

    if (e.key == "Enter" && isWriting.current) {
      let lastText = items[items.length - 1]
      lastText.text = lastText.text + "\n"
      setItems(items.concat());
    }

    if (e.key.length == 1 && !e.ctrlKey) {
      if (showToolbar) {
        if (e.key == ("1")) {
          setTool("pen")
        }
        if (e.key == ("2")) {
          setTool("eraser")
        }
        if (e.key == ("3")) {
          setTool("line")
        }
        if (e.key == ("4")) {
          setTool("circle")
        }
        if (e.key == ("5")) {
          setTool("rectangle")
        }
      }

      if (isWriting.current) {
        let lastText = items[items.length - 1]
        lastText.text = lastText.text + e.key
        setItems(items.concat());
      }

      if (doingNothing() && e.code != "Space") {
        setItems([...items, { type: "text", fontSize: fontSize, text: e.key, x: mouseX - 15, y: mouseY - 30 }]);
        isWriting.current = true;
      }

      showHelper.current = false;
    }

    if (e.ctrlKey && e.key == "z") {
      items.splice(items.length - 1, 1)
      setItems(items.concat());
    }
  }

  function doingNothing() {
    return (!isWriting.current && !isDrawing.current && !showToolbar)
  }

  const handleMouseDown = (e) => {
    if (doingNothing()) {
      if (tool == "pen" || tool == "line" || tool == "eraser") {
        isDrawing.current = true;
        const pos = e.target.getStage().getPointerPosition();
        setItems([...items, { type: "line", tool, strokeWidth, points: [pos.x, pos.y] }]);
      }
      if (tool == "circle") {
        isDrawing.current = true;
        const pos = e.target.getStage().getPointerPosition();
        setItems([...items, { type: "circle", tool, strokeWidth, startX: pos.x, startY: pos.y, x: pos.x, y: pos.y, radius: 0 }]);
      }
      if (tool == "rectangle") {
        isDrawing.current = true;
        const pos = e.target.getStage().getPointerPosition();
        setItems([...items, { type: "rectangle", tool, strokeWidth, x: pos.x, y: pos.y, width: 0, height: 0 }]);
      }
    }

    showHelper.current = false;
  };

  const handleMouseWheel = (e) => {
    if (e.evt.deltaY > 0) {
      if (doingNothing()) {
        if (strokeWidth > 1) {
          setStrokeWidth(strokeWidth - 1)
        }
      }
      if (isWriting.current && fontSize > 0) {
        setFontSize(fontSize - 1)
        let lastText = items[items.length - 1]
        lastText.fontSize = fontSize
      }
    } else {
      if (doingNothing()) {
        if (strokeWidth <= 30) {
          setStrokeWidth(strokeWidth + 1)
        }
      }
      if (isWriting.current) {
        setFontSize(fontSize + 1)
        let lastText = items[items.length - 1]
        lastText.fontSize = fontSize
      }
    }
  };

  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    setMouseX(point.x)
    setMouseY(point.y)

    if (isWriting.current) {
      let lastText = items[items.length - 1]
      lastText.x = point.x - 15
      lastText.y = point.y - 30
    }

    if (isDrawing.current) {
      if (tool == "pen" || tool == "eraser") {
        let lastLine = items[items.length - 1];
        // add point
        lastLine.points = lastLine.points.concat([point.x, point.y]);

        // replace last
        //items.splice(items.length - 1, 1, lastLine);
        setItems(items.concat());
      }
      if (tool == "line") {
        let lastLine = items[items.length - 1];
        let startX = lastLine.points[0]
        let startY = lastLine.points[1]
        lastLine.points = [startX, startY, point.x, point.y]
      }

      if (tool == "circle") {
        let lastCircle = items[items.length - 1];
        lastCircle.x = 0.5 * (point.x + lastCircle.startX)
        lastCircle.y = 0.5 * (point.y + lastCircle.startY)
        let xDistanceToCenter = Math.abs(lastCircle.x - lastCircle.startX)
        let yDistanceToCenter = Math.abs(lastCircle.y - lastCircle.startY)
        let distanceToCenter = Math.sqrt(xDistanceToCenter ** 2 + yDistanceToCenter ** 2)
        lastCircle.radius = distanceToCenter
      }

      if (tool == "rectangle") {
        let lastRectangle = items[items.length - 1];
        lastRectangle.height = point.y - lastRectangle.y
        lastRectangle.width = point.x - lastRectangle.x
      }
    }
  };

  const handleMouseUp = (e) => {


    if (isDrawing.current && tool != "circle" && tool != "rectangle") {
      //If last line is a single point, add an identical point to make a line looking like a point
      let lastLine = items[items.length - 1];
      if (lastLine.points.length == 2) {
        lastLine.points = lastLine.points.concat(lastLine.points)
      }
      setItems(items.concat());
    }

    isWriting.current = false;
    isDrawing.current = false;
  };

  function toolBoxColor(t) {
    if (t == tool) {
      return "#404040"
    }
    else {
      return "#181818"
    }
  }

  const handlePencilClick = (e) => {
    setTool("pen")
  }

  const handleEraserClick = (e) => {
    setTool("eraser")
  }

  const handleLineClick = (e) => {
    setTool("line")
  }

  const handleCircleClick = (e) => {
    setTool("circle")
  }

  const handleRectangleClick = (e) => {
    setTool("rectangle")
  }

  function handleStartOverClick(items) {
    setItems([])
  }

  return (
    <div >
      <Stage
        width={window.innerWidth}
        height={window.innerHeight}
        onMouseDown={handleMouseDown}
        onMousemove={handleMouseMove}
        onMouseup={handleMouseUp}
        onWheel={handleMouseWheel}
        style={{ backgroundColor: '#181818' }}
      >
        <Layer>
          {items.map((item, i) => {
            if (item.type == "line") {
              return <Line
                key={i}
                points={item.points}
                stroke="#E4E6EB"
                strokeWidth={item.strokeWidth}
                tension={0.5}
                lineCap="round"
                lineJoin="round"
                globalCompositeOperation={item.tool === 'eraser' ? 'destination-out' : 'source-over'}
              />
            }

            if (item.type == "text") {
              return <Text key={i} text={item.text} fontSize={item.fontSize} fill="#E4E6EB" x={item.x} y={item.y} />
            }

            if (item.type == "circle") {
              return <Circle x={item.x} y={item.y} radius={item.radius} strokeWidth={item.strokeWidth} stroke="#E4E6EB" />
            }

            if (item.type == "rectangle") {
              return <Rect x={item.x} y={item.y} width={item.width} height={item.height} strokeWidth={item.strokeWidth} stroke="#E4E6EB" />
            }
          }
          )}
        </Layer>
        <Layer id="icons">

          <Rect opacity={0.8} fill="#181818" visible={showToolbar} x={0} y={0} width={window.innerWidth} height={window.innerHeight}></Rect>


          <Text visible={showHelper.current} text={"Click anywhere to start drawing"} fontSize={30} x={window.innerWidth / 2 - 220} y={window.innerHeight / 2 - 120} fill="#282828" />
          <Text visible={showHelper.current} text={"Press any letter to start writing"} fontSize={30} x={window.innerWidth / 2 - 220} y={window.innerHeight / 2 - 40} fill="#282828" />
          {spaceIcon(window, showHelper.current)}
          <Text visible={showHelper.current} text={"to show toolbar"} fontSize={30} x={window.innerWidth / 2 - 100} y={window.innerHeight / 2 + 40} fill="#282828" />
          {ctrlZIcon(window, showHelper.current)}
          <Text visible={showHelper.current} text={"to regret"} fontSize={30} x={window.innerWidth / 2 - 100} y={window.innerHeight / 2 + 120} fill="#282828" />
          <Text visible={showHelper.current} text={"Scroll to adjust \nstroke width"} fontSize={30} x={150} y={window.innerHeight / 2 - 25} fill="#282828" />

          {currentToolIcon(tool, window, strokeWidth)}

          <Rect fill={toolBoxColor("pen")} onClick={handlePencilClick} visible={showToolbar} x={window.innerWidth / 2 - 250} y={window.innerHeight / 2 - 50} stroke="#E4E6EB" width={100} height={100}></Rect>
          <Rect fill={toolBoxColor("eraser")} onClick={handleEraserClick} visible={showToolbar} x={window.innerWidth / 2 - 150} y={window.innerHeight / 2 - 50} stroke="#E4E6EB" width={100} height={100}></Rect>
          <Rect fill={toolBoxColor("line")} onClick={handleLineClick} visible={showToolbar} x={window.innerWidth / 2 - 50} y={window.innerHeight / 2 - 50} stroke="#E4E6EB" width={100} height={100}></Rect>
          <Rect fill={toolBoxColor("circle")} onClick={handleCircleClick} visible={showToolbar} x={window.innerWidth / 2 + 50} y={window.innerHeight / 2 - 50} stroke="#E4E6EB" width={100} height={100}></Rect>
          <Rect fill={toolBoxColor("rectangle")} onClick={handleRectangleClick} visible={showToolbar} x={window.innerWidth / 2 + 150} y={window.innerHeight / 2 - 50} stroke="#E4E6EB" width={100} height={100}></Rect>

          <Rect visible={showToolbar} onClick={handleStartOverClick} fill="#181818" x={window.innerWidth / 2 - 75} y={window.innerHeight / 6} height={45} width={150} strokeWidth={1} stroke="#E4E6EB" />
          <Text visible={showToolbar} onClick={handleStartOverClick} text={"Start over"} fontSize={25} x={window.innerWidth / 2 - 55} y={window.innerHeight / 6 +10} fill="#E4E6EB" />

          <Text visible={showToolbar} text={"1"} fontSize={20} x={window.innerWidth / 2 - 200 + 25} y={window.innerHeight / 2 + 25} fill="#E4E6EB" />
          <Text visible={showToolbar} text={"2"} fontSize={20} x={window.innerWidth / 2 - 100 + 25} y={window.innerHeight / 2 + 25} fill="#E4E6EB" />
          <Text visible={showToolbar} text={"3"} fontSize={20} x={window.innerWidth / 2 + 25} y={window.innerHeight / 2 + 25} fill="#E4E6EB" />
          <Text visible={showToolbar} text={"4"} fontSize={20} x={window.innerWidth / 2 + 100 + 25} y={window.innerHeight / 2 + 25} fill="#E4E6EB" />
          <Text visible={showToolbar} text={"5"} fontSize={20} x={window.innerWidth / 2 + 200 + 25} y={window.innerHeight / 2 + 25} fill="#E4E6EB" />

          <Line
            onClick={handlePencilClick}
            visible={showToolbar}
            points={[window.innerWidth / 2 - 200, window.innerHeight / 2, window.innerWidth / 2 - 200, window.innerHeight / 2]}
            stroke="#E4E6EB"
            strokeWidth={10}
            tension={0.5}
            lineCap="round"
            lineJoin="round"
          />

          <Circle onClick={handleEraserClick} visible={showToolbar} x={window.innerWidth / 2 - 100} y={window.innerHeight / 2} radius={5} strokeWidth={0.3} stroke="#E4E6EB" />

          <Line
            onClick={handleLineClick}
            visible={showToolbar}
            points={[window.innerWidth / 2, window.innerHeight / 2 - 20, window.innerWidth / 2, window.innerHeight / 2 + 20]}
            stroke="#E4E6EB"
            strokeWidth={2}
            tension={0.5}
            lineCap="round"
            lineJoin="round"
          />

          <Circle onClick={handleCircleClick} visible={showToolbar} x={window.innerWidth / 2 + 100} y={window.innerHeight / 2} radius={20} strokeWidth={2} stroke="#E4E6EB" />
          <Rect onClick={handleRectangleClick} visible={showToolbar} x={window.innerWidth / 2 + 180} y={window.innerHeight / 2 - 20} strokeWidth={2} stroke="#E4E6EB" width={40} height={40}></Rect>


        </Layer>
      </Stage>
    </div>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
