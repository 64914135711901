import { Stage, Layer, Line, Text, Image, Circle, Rect } from 'react-konva';

export function spaceIcon(window, showHelper) {
    return (
        <div>
            <Rect visible={showHelper} fill="#181818" x={window.innerWidth / 2 - 220} y={window.innerHeight / 2 + 40} height={30} width={100} strokeWidth={1} stroke="#282828" />
            <Text visible={showHelper} text={"space"} fontSize={20} x={window.innerWidth / 2 - 195 } y={window.innerHeight / 2 + 45} fill="#282828" />
        </div>
    )
}

export function ctrlZIcon(window, showHelper) {
    return (
        <div>
            <Rect visible={showHelper} fill="#181818" x={window.innerWidth / 2 - 220} y={window.innerHeight / 2 + 120} height={30} width={50} strokeWidth={1} stroke="#282828" />
            <Rect visible={showHelper} fill="#181818" x={window.innerWidth / 2 - 150} y={window.innerHeight / 2 + 120} height={30} width={30} strokeWidth={1} stroke="#282828" />
            <Text visible={showHelper} text={"ctrl"} fontSize={20} x={window.innerWidth / 2 - 209} y={window.innerHeight / 2 + 126} fill="#282828" />
            <Text visible={showHelper} text={"+"} fontSize={20} x={window.innerWidth / 2 - 166} y={window.innerHeight / 2 + 126} fill="#282828" />
            <Text visible={showHelper} text={"z"} fontSize={20} x={window.innerWidth / 2 - 140} y={window.innerHeight / 2 + 125} fill="#282828" />
        </div>
    )
}

export function currentToolIcon(tool, window, strokeWidth) {
    if (tool == "pen" || tool == "eraser") {
        return <Line
            key={"strokeWidth"}
            points={[100, window.innerHeight / 2, 100, window.innerHeight / 2]}
            stroke="#E4E6EB"
            strokeWidth={strokeWidth}
            tension={0.5}
            lineCap="round"
            lineJoin="round"
        />
    }
    if (tool == "line") {
        return <Line
            points={[100, window.innerHeight / 2 - 20, 100, window.innerHeight / 2 + 20]}
            stroke="#E4E6EB"
            strokeWidth={strokeWidth}
            tension={0.5}
            lineCap="round"
            lineJoin="round"
        />
    }
    if (tool == "circle") {
        return <Circle fill="#181818" x={100} y={window.innerHeight / 2} radius={20} strokeWidth={strokeWidth} stroke="#E4E6EB" />
    }
    if (tool == "rectangle") {
        return <Rect fill="#181818" x={100 - 20} y={window.innerHeight / 2 - 20} height={40} width={40} strokeWidth={strokeWidth} stroke="#E4E6EB" />
    }
}